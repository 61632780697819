<template>
  <app-layout>
    <div class="flex flex-col items-center py-4">
      <div class="w-full px-12">
        <div class="flex flex-row items-center py-4">
          <h1 class="text-lg font-bold">Inventario</h1>
          <div class="flex flex-row items-center ml-auto">
            <el-button @click="goImportExport" :class="{ 'p-2' : isMobile }">
              <span v-if="!isMobile">Importar/Exportar</span>
              <i v-if="isMobile" class='text-md bx bx-cog'></i>
            </el-button>
            <sa-button
              type="primary"
              :disabled="!$store.state.account.canEditStockQuantities"
              class="items-center px-3 py-3 font-bold rounded"
              @click="handleCreateProductClick">
              Agregar producto
            </sa-button>
          </div>
        </div>
        <div class="flex flex-col space-y-6">
          <content-card>
            <products-list :suppliers="suppliers" />
          </content-card>
        </div>
      </div>
    </div>
  </app-layout>
</template>
<script>
import AppLayout from '@/components/layouts/AppLayout.vue';
import ContentCard from '@/components/molecules/ContentCard.vue';
import ProductsList from '@/views/app/products/ProductsList.vue';
import responsiveSize from "@/mixins/responsiveSize";
import { ImportExportType } from '@/constants';

export default {
  name: 'ProductsIndex',
  mixins: [responsiveSize],
  components: {
    AppLayout,
    ContentCard,
    ProductsList
  },
  data() {
    return {
      query: {
        state: 'Open'
      },
      isMobile: false,
      suppliers: [],
    };
  },
  created() {
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    resize() {
      const percentageWidth = this.getPercentageWidth();
      this.isMobile = percentageWidth === '90%';
    },
    goImportExport() {
      if (!this.$store.state.business.id) {
        this.$toastr.i("Tienes que estar en una sucursal para poder importar productos");
        return;
      }
      const isOwner = this.$store.state.account.accountType === 1;
      const { canViewAdministration } = this.$store.state.account;
      if(isOwner || canViewAdministration){
        this.$router.push({
          name: 'administration.importExport',
          params: { importExportType: ImportExportType.Product },
        });
        return;
      }
      this.$swal('Aviso',
        'No tienes el permiso para realizar esta acción ',
        'error')
    },
    handleCreateProductClick() {
      if (this.$store.state.business.id) {
        this.$router.push({ name: 'products.create' });
      } else {
        this.$toastr.i("Tienes que estar en una sucursal para poder crear productos");
      }
    },
  }
}
</script>
