var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('LoadingScreen',{attrs:{"isLoading":_vm.loading}}),_c('div',{},[_c('el-tabs',{model:{value:(_vm.productsSearch.all),callback:function ($$v) {_vm.$set(_vm.productsSearch, "all", $$v)},expression:"productsSearch.all"}},[_c('el-tab-pane',{attrs:{"label":"Todos","name":"Todos"}})],1),_c('div',{staticClass:"flex flex-col lg:flex-row mt-2"},[_c('div',{staticClass:"flex relative"},[_vm._m(0),_c('el-input',{staticStyle:{"width":"120px"},attrs:{"min":"0","max":"100","type":"number","disabled":!_vm.selection.length,"title":"Selecciona productos para aumentar precios"},model:{value:(_vm.percentageIncrease),callback:function ($$v) {_vm.percentageIncrease=$$v},expression:"percentageIncrease"}},[_c('i',{staticClass:"bx bx-send text-2xl",class:{
              'text-purple-800 cursor-pointer':
                _vm.selection.length &&
                _vm.percentageIncrease >= 0 &&
                _vm.percentageIncrease <= 100,
              'text-red-800 cursor-not-allowed':
                !_vm.selection.length ||
                _vm.percentageIncrease < 0 ||
                _vm.percentageIncrease > 100,
            },staticStyle:{"margin-top":"5px"},attrs:{"slot":"suffix"},on:{"click":function($event){$event.stopPropagation();return _vm.updateProductsPrice.apply(null, arguments)}},slot:"suffix"})])],1),_c('div',{staticClass:"ml-auto flex"},[_c('pagination',{attrs:{"pagination":_vm.pagination},on:{"onPaginationChange":_vm.onPaginationChange}})],1)]),_c('div',{staticClass:"py-2"},[_c('div',{staticClass:"block w-full overflow-x-auto"},[_c('table',{staticClass:"items-center w-full border-collapse"},[_c('thead',[_c('tr',{staticClass:"px-2 border-b-2"},[(_vm.$store.state.account.canEditStockQuantities)?_c('th',{staticClass:"py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap",staticStyle:{"padding-left":"8px"}},[_c('sa-checkbox',{attrs:{"checked":Boolean(
                      _vm.selection.length && _vm.products.length === _vm.selection.length
                    ),"indeterminate":Boolean(
                      _vm.selection.length && _vm.products.length > _vm.selection.length
                    ),"onclick":_vm.checkboxChange}})],1):_vm._e(),(
                  Boolean(!_vm.selection.length) &&
                  _vm.$store.state.account.accountType ===
                    _vm.AccountTypeEnum.Owner &&
                  _vm.isProMembership
                )?_c('th',{staticClass:"py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"}):_vm._e(),(Boolean(!_vm.selection.length))?_c('th',{staticClass:"py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"},[_vm._v(" Producto ")]):_vm._e(),(Boolean(!_vm.selection.length))?_c('th',{staticClass:"py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"},[_vm._v(" Categoria ")]):_vm._e(),(Boolean(!_vm.selection.length))?_c('th',{staticClass:"py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"},[_vm._v(" Proveedor ")]):_vm._e(),(Boolean(!_vm.selection.length))?_c('th',{staticClass:"py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"},[_vm._v(" Detalles ")]):_vm._e(),(Boolean(!_vm.selection.length))?_c('th',{staticClass:"py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"},[_vm._v(" Color ")]):_vm._e(),(
                  Boolean(!_vm.selection.length) &&
                  _vm.$store.state.account.canEditOrdersPrices
                )?_c('th',{staticClass:"py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"},[_vm._v(" Costo ")]):_vm._e(),(Boolean(!_vm.selection.length))?_c('th',{staticClass:"py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"},[_vm._v(" Precio ")]):_vm._e(),(Boolean(!_vm.selection.length))?_c('th',{staticClass:"py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"},[_vm._v(" Existencias ")]):_vm._e(),(Boolean(_vm.selection.length))?_c('th',{staticClass:"py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap flex items-center"},[_c('span',{staticStyle:{"margin-right":"16px"}},[_vm._v(" "+_vm._s(_vm.selection.length)+" producto"+_vm._s(_vm.selection.length > 1 ? "s" : "")+" seleccionado"+_vm._s(_vm.selection.length > 1 ? "s" : "")+" ")]),_c('el-button',{on:{"click":function($event){$event.stopPropagation();return _vm.deleteProducts.apply(null, arguments)}}},[_vm._v("Eliminar")])],1):_vm._e()])]),_c('tbody',[_c('tr',{staticClass:"hover:bg-gray-100 hover:shadow"},[_c('td'),(
                  _vm.$store.state.account.accountType ===
                    _vm.AccountTypeEnum.Owner && _vm.isProMembership
                )?_c('td'):_vm._e(),_c('td',[_c('div',{staticClass:"sm:col-start-1"},[_c('el-input',{attrs:{"placeholder":"Producto"},model:{value:(_vm.productsSearch.product),callback:function ($$v) {_vm.$set(_vm.productsSearch, "product", $$v)},expression:"productsSearch.product"}})],1)]),_c('td',[_c('div',{staticClass:"sm:col-start-1"},[_c('el-input',{attrs:{"placeholder":"Categoría"},model:{value:(_vm.productsSearch.categoryName),callback:function ($$v) {_vm.$set(_vm.productsSearch, "categoryName", $$v)},expression:"productsSearch.categoryName"}})],1)]),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td',[_c('div',{staticClass:"sm:col-start-1"},[_c('el-input',{attrs:{"placeholder":"Existencia","type":"number"},model:{value:(_vm.productsSearch.quantity),callback:function ($$v) {_vm.$set(_vm.productsSearch, "quantity", $$v)},expression:"productsSearch.quantity"}})],1)])]),_vm._l((_vm.paginated),function(product){return _c('tr',{key:product.id,staticClass:"hover:bg-gray-100 hover:shadow"},[(_vm.$store.state.account.canEditStockQuantities)?_c('td',{staticClass:"p-2 border-t"},[_c('sa-checkbox',{attrs:{"id":product.id,"checked":_vm.selection.includes(product.id),"onclick":_vm.checkboxChange}})],1):_vm._e(),(
                  _vm.$store.state.account.accountType ===
                    _vm.AccountTypeEnum.Owner && _vm.isProMembership
                )?_c('td',{staticClass:"p-2 border-t"},[_c('sa-action-button',{attrs:{"title":"Configurar Alertas","icon":"el-icon-bell","type":"default"},on:{"click":function($event){return _vm.openAlert(product)}}})],1):_vm._e(),_c('td',{staticClass:"p-2 border-t"},[_c('a',{staticClass:"text-gray-500 hover:text-purple-500",on:{"click":function($event){return _vm.$router.push({
                      name: 'products.read',
                      params: { id: product.id },
                    })}}},[_vm._v(" "+_vm._s(product.name)+" ")])]),_c('td',{staticClass:"p-2 border-t"},[_vm._v(" "+_vm._s(product.category)+" ")]),_c('td',{staticClass:"p-2 border-t"},[_vm._v(" "+_vm._s(_vm.getSupplier(product.supplierId))+" ")]),_c('td',{staticClass:"p-2 border-t"},[_vm._v(" "+_vm._s(product.details)+" ")]),_c('td',{staticClass:"p-2 border-t"},[_vm._v(" "+_vm._s(product.color)+" ")]),(_vm.$store.state.account.canEditOrdersPrices)?_c('td',{staticClass:"p-2 border-t"},[_vm._v(" "+_vm._s(_vm.moneyFormat(product.cost))+" ")]):_vm._e(),_c('td',{staticClass:"p-2 border-t"},[_vm._v(" "+_vm._s(_vm.moneyFormat(product.price))+" ")]),_c('td',{staticClass:"p-2 border-t",staticStyle:{"width":"240px"}},[(_vm.productsQtyEditing[product.id])?_c('div',[_c('el-input',{staticStyle:{"width":"120px","margin-right":"8px"},attrs:{"type":"number","min":"0"},model:{value:(_vm.productsQtyEditing[product.id].quantity),callback:function ($$v) {_vm.$set(_vm.productsQtyEditing[product.id], "quantity", $$v)},expression:"productsQtyEditing[product.id].quantity"}}),_c('el-button',{staticClass:"text-gray-700 hover:text-purple-500",on:{"click":function($event){$event.stopPropagation();return _vm.updateProductQty(product.id)}}},[_vm._v("Guardar")])],1):_vm._e(),(
                    !_vm.productsQtyEditing[product.id] &&
                    _vm.$store.state.account.canEditStockQuantities
                  )?_c('a',{staticClass:"text-gray-500 hover:text-purple-500",on:{"click":function($event){$event.stopPropagation();return _vm.editProductQuantity(product)}}},[_c('i',{staticClass:"font-bold bx bx-sort-alt-2"}),_vm._v(" "+_vm._s(product.quantity)+" ")]):_vm._e(),(!_vm.$store.state.account.canEditStockQuantities)?_c('span',[_vm._v(" "+_vm._s(product.quantity)+" ")]):_vm._e()])])})],2)])])]),_c('el-dialog',{attrs:{"visible":_vm.showAlert},on:{"update:visible":function($event){_vm.showAlert=$event},"close":function($event){return _vm.closeAlert()}}},[_c('el-form',{attrs:{"model":_vm.alert}},[_c('el-form-item',{attrs:{"prop":"min"}},[_c('strong',[_vm._v("Alerta por Stock Minimo")]),_c('el-input',{attrs:{"type":"number","min":"0"},model:{value:(_vm.alert.min),callback:function ($$v) {_vm.$set(_vm.alert, "min", $$v)},expression:"alert.min"}})],1),_c('el-form-item',{attrs:{"prop":"max"}},[_c('strong',[_vm._v("Alerta por Stock Maximo")]),_c('el-input',{attrs:{"type":"number","min":"0"},model:{value:(_vm.alert.max),callback:function ($$v) {_vm.$set(_vm.alert, "max", $$v)},expression:"alert.max"}})],1),_c('el-form-item',{staticClass:"text-right"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.saveAlert()}}},[_vm._v(" Guardar ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"shrinked-label"},[_c('span',[_vm._v("Aumentar precio a múltiples productos (%)")])])
}]

export { render, staticRenderFns }