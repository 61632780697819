<template>
  <div>
    <LoadingScreen :isLoading="loading"></LoadingScreen>
    <div class="">
      <el-tabs v-model="productsSearch.all">
        <el-tab-pane label="Todos" name="Todos" />
      </el-tabs>
      <div class="flex flex-col lg:flex-row mt-2">
        <div class="flex relative">
          <label class="shrinked-label">
            <span>Aumentar precio a múltiples productos (%)</span>
          </label>
          <el-input
            v-model="percentageIncrease"
            min="0"
            max="100"
            type="number"
            style="width: 120px"
            :disabled="!selection.length"
            title="Selecciona productos para aumentar precios"
          >
            <i
              slot="suffix"
              style="margin-top: 5px"
              class="bx bx-send text-2xl"
              :class="{
                'text-purple-800 cursor-pointer':
                  selection.length &&
                  percentageIncrease >= 0 &&
                  percentageIncrease <= 100,
                'text-red-800 cursor-not-allowed':
                  !selection.length ||
                  percentageIncrease < 0 ||
                  percentageIncrease > 100,
              }"
              @click.stop="updateProductsPrice"
            />
          </el-input>
        </div>
        <div class="ml-auto flex">
          <pagination
            :pagination="pagination"
            @onPaginationChange="onPaginationChange"
          />
        </div>
      </div>
      <div class="py-2">
        <div class="block w-full overflow-x-auto">
          <table class="items-center w-full border-collapse">
            <thead>
              <tr class="px-2 border-b-2">
                <th
                  v-if="$store.state.account.canEditStockQuantities"
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
                  style="padding-left: 8px"
                >
                  <sa-checkbox
                    :checked="
                      Boolean(
                        selection.length && products.length === selection.length
                      )
                    "
                    :indeterminate="
                      Boolean(
                        selection.length && products.length > selection.length
                      )
                    "
                    :onclick="checkboxChange"
                  ></sa-checkbox>
                </th>
                <th
                  v-if="
                    Boolean(!selection.length) &&
                    $store.state.account.accountType ===
                      AccountTypeEnum.Owner &&
                    isProMembership
                  "
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
                >
                  <!-- Configuracion de Alertas -->
                </th>
                <th
                  v-if="Boolean(!selection.length)"
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
                >
                  Producto
                </th>
                <th
                  v-if="Boolean(!selection.length)"
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
                >
                  Categoria
                </th>
                <th
                  v-if="Boolean(!selection.length)"
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
                >
                  Proveedor
                </th>
                <th
                  v-if="Boolean(!selection.length)"
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
                >
                  Detalles
                </th>
                <th
                  v-if="Boolean(!selection.length)"
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
                >
                  Color
                </th>
                <th
                  v-if="
                    Boolean(!selection.length) &&
                    $store.state.account.canEditOrdersPrices
                  "
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
                >
                  Costo
                </th>
                <th
                  v-if="Boolean(!selection.length)"
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
                >
                  Precio
                </th>
                <th
                  v-if="Boolean(!selection.length)"
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
                >
                  Existencias
                </th>
                <th
                  v-if="Boolean(selection.length)"
                  class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap flex items-center"
                >
                  <span style="margin-right: 16px">
                    {{ selection.length }} producto{{
                      selection.length > 1 ? "s" : ""
                    }}
                    seleccionado{{ selection.length > 1 ? "s" : "" }}
                  </span>
                  <el-button @click.stop="deleteProducts">Eliminar</el-button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="hover:bg-gray-100 hover:shadow">
                <td></td>
                <td
                  v-if="
                    $store.state.account.accountType ===
                      AccountTypeEnum.Owner && isProMembership
                  "
                ></td>
                <td>
                  <div class="sm:col-start-1">
                    <el-input
                      placeholder="Producto"
                      v-model="productsSearch.product"
                    >
                    </el-input>
                  </div>
                </td>
                <td>
                  <div class="sm:col-start-1">
                    <el-input
                      placeholder="Categoría"
                      v-model="productsSearch.categoryName"
                    >
                    </el-input>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <div class="sm:col-start-1">
                    <el-input
                      placeholder="Existencia"
                      v-model="productsSearch.quantity"
                      type="number"
                    >
                    </el-input>
                  </div>
                </td>
              </tr>
              <tr
                v-for="product in paginated"
                :key="product.id"
                class="hover:bg-gray-100 hover:shadow"
              >
                <td
                  class="p-2 border-t"
                  v-if="$store.state.account.canEditStockQuantities"
                >
                  <sa-checkbox
                    :id="product.id"
                    :checked="selection.includes(product.id)"
                    :onclick="checkboxChange"
                  ></sa-checkbox>
                </td>
                <td
                  class="p-2 border-t"
                  v-if="
                    $store.state.account.accountType ===
                      AccountTypeEnum.Owner && isProMembership
                  "
                >
                  <sa-action-button
                    title="Configurar Alertas"
                    icon="el-icon-bell"
                    type="default"
                    @click="openAlert(product)"
                  >
                  </sa-action-button>
                </td>
                <td class="p-2 border-t">
                  <a
                    class="text-gray-500 hover:text-purple-500"
                    @click="
                      $router.push({
                        name: 'products.read',
                        params: { id: product.id },
                      })
                    "
                  >
                    {{ product.name }}
                  </a>
                </td>
                <td class="p-2 border-t">
                  {{ product.category }}
                </td>
                <td class="p-2 border-t">
                  {{ getSupplier(product.supplierId) }}
                </td>
                <td class="p-2 border-t">
                  {{ product.details }}
                </td>
                <td class="p-2 border-t">
                  {{ product.color }}
                </td>
                <td
                  class="p-2 border-t"
                  v-if="$store.state.account.canEditOrdersPrices"
                >
                  {{ moneyFormat(product.cost) }}
                </td>
                <td class="p-2 border-t">
                  {{ moneyFormat(product.price) }}
                </td>
                <td class="p-2 border-t" style="width: 240px">
                  <div v-if="productsQtyEditing[product.id]">
                    <el-input
                      style="width: 120px; margin-right: 8px"
                      type="number"
                      min="0"
                      v-model="productsQtyEditing[product.id].quantity"
                    />
                    <el-button
                      class="text-gray-700 hover:text-purple-500"
                      @click.stop="updateProductQty(product.id)"
                      >Guardar</el-button
                    >
                  </div>
                  <a
                    v-if="
                      !productsQtyEditing[product.id] &&
                      $store.state.account.canEditStockQuantities
                    "
                    class="text-gray-500 hover:text-purple-500"
                    @click.stop="editProductQuantity(product)"
                  >
                    <i class="font-bold bx bx-sort-alt-2" />
                    {{ product.quantity }}
                  </a>
                  <span v-if="!$store.state.account.canEditStockQuantities">
                    {{ product.quantity }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <el-dialog :visible.sync="showAlert" @close="closeAlert()">
        <el-form :model="alert">
          <el-form-item prop="min">
            <strong>Alerta por Stock Minimo</strong>
            <el-input v-model="alert.min" type="number" min="0" />
          </el-form-item>
          <el-form-item prop="max">
            <strong>Alerta por Stock Maximo</strong>
            <el-input v-model="alert.max" type="number" min="0" />
          </el-form-item>
          <el-form-item class="text-right">
            <el-button type="primary" @click="saveAlert()"> Guardar </el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<style scoped>
.shrinked-label {
  width: max-content;
  position: absolute;
  top: -6px;
  left: 0px;
  transform: translateY(-50%);
  font-size: 12px;
  color: #888;
  transition: transform 0.3s, font-size 0.3s;
}

.el-input__inner:focus + .shrinked-label,
.el-input__inner:not(:placeholder-shown) + .shrinked-label {
  transform: translateY(-80%) scale(0.8);
  font-size: 10px;
}
</style>
<script>
import _ from "lodash";
import TheHeaderAppServices from "@/components/organisms/services/TheHeaderAppServices";
import ProductService from "@/services/ProductService";
import numbers from "@/utils/numbers";
import { AccountTypeEnum } from "@/constants";
import SupplierService from "@/services/SupplierService";
import LoadingScreen from "@/components/layouts/LoadingScreen";

export default {
  name: "ProductsList",
  components: {
    Pagination: () => import("@/components/molecules/Pagination"),
    LoadingScreen,
  },
  data() {
    return {
      loading: true,
      isProMembership:
        this.$store.state.account.membershipType === 2 ||
        this.$store.state.account.membershipType === 3,
      showAlert: false,
      alert: {
        productId: null,
        min: null,
        max: null,
      },
      AccountTypeEnum,
      pagination: {
        currentPage: 1,
        perPage: 10,
        totalPages: 0,
      },
      selection: [],
      products: [],
      categories: [],
      productsQtyEditing: {},
      productsSearch: {
        all: "Todos",
        category: undefined,
        product: "",
        quantity: null,
        categoryName: "",
      },
      percentageIncrease: 0,
      headerAppSubscription: null,
      suppliers: [],
    };
  },
  created() {
    this.loading = true;
    this.headerAppSubscription = TheHeaderAppServices.command.subscribe(
      this.handleHeaderAppCommands
    );
  },
  destroyed() {
    this.headerAppSubscription.unsubscribe();
  },
  async mounted() {
    await this.loadProducts(this.productsSearch);
    if (this.suppliers.length === 0) await this.loadSuppliers();
    this.loading = false;
  },
  watch: {
    "productsSearch.category": {
      deep: true,
      handler: _.debounce(async function () {
        await this.loadProducts(this.productsSearch);
      }, 300),
    },
    "productsSearch.product": {
      deep: true,
      handler: _.debounce(async function () {
        await this.loadProducts(this.productsSearch);
      }, 300),
    },
    "productsSearch.quantity": {
      deep: true,
      handler: _.debounce(async function () {
        await this.loadProducts(this.productsSearch);
      }, 300),
    },
    "productsSearch.categoryName": {
      deep: true,
      handler: _.debounce(async function () {
        await this.loadProducts(this.productsSearch);
      }, 300),
    },
  },
  computed: {
    paginated() {
      const products = [...this.products];
      const { currentPage, perPage } = this.pagination;
      const pages = [];
      while (products.length) {
        pages.push(products.splice(0, perPage));
      }
      return pages[currentPage - 1];
    },
  },
  methods: {
    openAlert(product) {
      this.showAlert = true;
      this.alert = {
        productId: product.id,
        min: product.minStockAlert,
        max: product.maxStockAlert,
      };
    },
    closeAlert() {
      this.showAlert = false;
      this.alert = {
        productId: null,
        min: null,
        max: null,
      };
    },
    async saveAlert() {
      this.loading = true;
      try {
        const data = {
          minStockAlert: this.alert.min,
          maxStockAlert: this.alert.max,
        };
        await ProductService.updateAlert({
          productId: this.alert.productId,
          alert: data,
        });
        this.$toastr.s(
          "Se guardaron correctamente los valores de alerta de stock"
        );
        this.closeAlert();
      } catch (error) {
        this.$toastr.e("Algo salió mal, intentanlo mas tarde");
      } finally {
        this.loading = false;
      }
    },
    categoryChange(category) {
      this.productsSearch.category = category || undefined;
    },
    categoryRemove() {
      this.productsSearch.category = undefined;
    },
    checkboxChange(productId) {
      if (productId) {
        this.selection.includes(productId)
          ? (this.selection = this.selection.filter((id) => id !== productId))
          : (this.selection = [...this.selection, productId]);
        return;
      }
      this.selection.length
        ? (this.selection = [])
        : (this.selection = this.products.map((product) => product.id));
    },
    editProductQuantity(product) {
      this.productsQtyEditing = {
        ...this.productsQtyEditing,
        [product.id]: product,
      };
    },
    async loadProducts(productsSearch) {
      const { category, categoryName, quantity, product } = productsSearch;
      this.products = await ProductService.getAll({
        businessInfoId: this.$store.state.business.id,
        category,
        categoryName,
        quantity,
        product,
      });
      this.pagination.currentPage = 1;
      this.pagination.totalPages =
        Math.ceil(this.products.length / this.pagination.perPage) || 1;
    },
    async deleteProducts() {
      const message =
        this.selection.length === 1
          ? `¿Está seguro que desea eliminar el producto ${
              this.products.find((p) => p.id === this.selection[0]).name
            }?`
          : "¿Está seguro que desea eliminar los productos seleccionados";
      this.$confirm(message, {
        type: "warning",
        confirmButtonText: "OK",
        cancelButtonText: "Cancelar",
      }).then(async () => {
        try {
          await ProductService.delete({ ids: this.selection });
          this.products = this.products.filter(
            (product) => !this.selection.includes(product.id)
          );
          this.selection = this.selection.filter(
            (id) => !this.selection.includes(id)
          );
        } catch (e) {
          console.log(e);
        }
      });
    },
    async updateProductQty(productId) {
      const { quantity } = this.productsQtyEditing[productId];
      const success = await ProductService.updateQty({
        id: productId,
        quantity,
      });
      this.$toastr.s("Se actualizo con exito");
      if (success) {
        const newProductsQtyEditing = { ...this.productsQtyEditing };
        delete newProductsQtyEditing[productId];
        this.productsQtyEditing = newProductsQtyEditing;
      }
    },
    async updateProductsPrice() {
      if (
        this.selection.length > 0 &&
        this.percentageIncrease > 0 &&
        this.percentageIncrease <= 100
      ) {
        this.loading = true;
        try {
          await ProductService.updatePrice({
            productIds: this.selection,
            percentageIncrease: this.percentageIncrease,
          });
          await this.loadProducts(this.productsSearch);
          this.percentageIncrease = 0;
          this.$toastr.s(
            this.selection.length === 1
              ? "Se actualizó el precio con exito"
              : "Se actualizaron los precios con exito"
          );
          this.selection = [];
        } catch (e) {
          this.$toastr.e("Algo salió mal, intentanlo mas tarde");
        } finally {
          this.loading = false;
        }
      }
    },
    onPaginationChange(pagination) {
      this.pagination = pagination;
    },
    moneyFormat(value) {
      const { currencySymbol } = this.$store.state.account;
      return numbers.moneyFormat(value, currencySymbol);
    },
    handleHeaderAppCommands({ type }) {
      switch (type) {
        case TheHeaderAppServices.commands.onBusinessChange:
          this.loadProducts(this.productsSearch);
          break;
        default:
      }
    },
    async loadSuppliers() {
      try {
        this.suppliers = await SupplierService.getAll({
          businessInfoId: this.$store.state.business.id,
        });
      } catch (e) {
        this.errorMessage = e;
      }
    },
    getSupplier(supplierId) {
      const supplier = this.suppliers.find((s) => s.id === supplierId);
      if (supplier) {
        return supplier.company;
      }
      return "";
    },
  },
};
</script>
